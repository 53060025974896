import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'

const BlogItem = ({ blog }) => {
  const { title, createdAt, imagePath, link } = blog;
  return <Grid container direction='row' style={{ flexWrap: 'nowrap', cursor: 'pointer' }}
    onClick={() => {
      console.log(link);
      window.open(link, '_blank');
    }}
  >
    <Grid item>
      <img width='80px' height='60px' src={imagePath} alt={title} />
    </Grid>
    <Grid item style={{ paddingLeft: '8px' }}>
      <Grid container direction='column' style={{ textAlign: 'left' }}>
        <Grid item>{title}</Grid>
        <Grid item>{createdAt}</Grid>
      </Grid>
    </Grid>
  </Grid>
}
export const BlogList = () => {
  const [blogItems, setBlogItems] = useState([]);
  useEffect(() => {
    // Define the URL
    const url = 'https://s3.ap-south-1.amazonaws.com/metadata.shams-nahid.com/blog-list.json';

    // Use fetch to get data from the URL
    fetch(url)
      .then(response => {
        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        // Parse the JSON from the response
        return response.json();
      })
      .then(data => {
        // Handle the JSON data
        console.log(data);
        setBlogItems(data);
        // You can do further processing here
      })
      .catch(error => {
        // Handle errors
        console.error('There has been a problem with your fetch operation:', error);
      });

  }, []);

  return <Grid container
    direction="column"
    justifyContent="center"
    alignItems="left"
    spacing={4}
    style={{ maxWidth: '600px', paddingTop: '20px' }}
  >
    <Grid item >Read my latest articles...</Grid>
    {blogItems.map((blog) => <Grid key={blog.title} item><BlogItem blog={blog} /></Grid>)}
  </Grid>

}